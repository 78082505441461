import "core-js/modules/es.array.push.js";
import { getStudentAnswerInfo, saveChangeScore, resetCheckState, getBeforeStudentAnswerInfo } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      Info: {
        id: '',
        score: 0
      },
      examData: {
        exam_id: '',
        question_no: ''
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.examData.exam_id = data.exam_id;
    this.examData.question_no = data.question_no;
    this.getInfo();
  },
  beforeUnmount() {
    //监听路由页面关闭(跳转),当组件被销毁之前触发该事件
    this.resetCheckState();
  },
  methods: {
    reset() {
      this.searchInfo.subject = '';
      this.searchInfo.qtpye = '';
      this.searchInfo.source = '';
    },
    search() {
      this.getList();
    },
    getInfo() {
      getStudentAnswerInfo(this.examData).then(res => {
        if (!res.data) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.$root.useRouter.push({
            path: "/examCheck/examCheckQuesList",
            query: {
              examId: this.examData.exam_id
            }
          });
        }
        this.Info = res.data;
      });
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    resetCheckState() {
      //重置状态
      resetCheckState({
        id: this.Info.id
      }).then(res => {});
    },
    saveScore(x) {
      //保存
      let allScore = this.Info.question ? this.Info.question.score : 0;
      if (this.Info.score > allScore) {
        this.$root.ElMessage.error('分数不能超过该题的总分【' + allScore + '】');
        return false;
      }
      saveChangeScore({
        id: this.Info.id,
        score: this.Info.score
      }).then(res => {
        if (x == 1) {
          this.getInfo();
        }
        if (x == 2) {
          this.$root.msgResut(res, this, 'goFor');
        }
        if (x == 3) {
          this.$root.msgResut(res, this, 'goBefore');
        }
      });
    },
    goFor() {
      let url = '/examCheck/examCheckQuesList?examId=' + this.examData.exam_id;
      window.localStorage.setItem('menuUrl', url);
      this.$root.useRouter.push({
        path: "/examCheck/examCheckQuesList",
        query: {
          examId: this.examData.exam_id
        }
      });
    },
    goBefore() {
      getBeforeStudentAnswerInfo({
        exam_id: this.examData.exam_id,
        question_no: this.examData.question_no,
        id: this.Info.id
      }).then(res => {
        if (res.success) {
          this.Info = res.data;
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};